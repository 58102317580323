const PySpark_Page = () => {
  return (
    <div className="flex p-4">
      <section className="w-1/4 py-10 px-5">
        <table className="border border-gray-200 w-full">
          <thead>
            <tr>
              <th colSpan={2} className="bg-orange-100 p-4 text-3xl font-bold">
                Table of Content
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-4 border border-gray-200">fd jf</td>
            </tr>
            <tr>
              <td className="p-4 border border-gray-200">fd jf</td>
            </tr>
            <tr>
              <td className="p-4 border border-gray-200">fd jf</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="w-1/2 py-10 px-5">
        <div className="p-4">
          <h1 className="text-4xl font-bold mb-4">Lorem ipsum dolor sit amet.</h1>
          <p className="text-lg mb-8">
            ipsum dolor sit amet consectetur, adipisicing elit.Lorem Nemo porro soluta, unde modi libero explicabo expedita. Obcaecati numquam illo velit.
          </p>
          <h2 className="text-3xl font-bold mb-4">Lorem ipsum dolor sit amet.</h2>
          <p className="text-lg mb-8">
            Machine Learning tutorial covers basic and advanced concepts, specially designed to cater to both students and experienced working professionals.

            This machine learning tutorial helps you gain a solid introduction to the fundamentals of machine learning and explore a wide range of techniques, including supervised, unsupervised, and reinforcement learning.

            Machine learning (ML) is a subdomain of artificial intelligence (AI) that focuses on developing systems that learn—or improve performance—based on the data they ingest. Artificial intelligence is a broad word that refers to systems or machines that resemble human intelligence. Machine learning and AI are frequently discussed together, and the terms are occasionally used interchangeably, although they do not signify the same thing. A crucial distinction is that, while all machine learning is AI, not all AI is machine learning.
          </p>
          <h2 className="text-3xl font-bold mb-4">Features of Machine learning</h2>
          <p className="text-lg mb-8">
            Machine learning is data driven technology. Large amount of data generated by organizations on daily bases. So, by notable relationships in data, organizations makes better decisions.
            Machine can learn itself from past data and automatically improve.
            From the given dataset it detects various patterns on data.
            For the big organizations branding is important and it will become more easy to target relatable customer base.
            It is similar to data mining because it is also deals with the huge amount of data.
          </p>
          <h2 className="text-3xl font-bold mb-4">Features of Machine learning</h2>
          <p className="text-lg mb-8">
            Machine learning is data driven technology. Large amount of data generated by organizations on daily bases. So, by notable relationships in data, organizations makes better decisions.
            Machine can learn itself from past data and automatically improve.
            From the given dataset it detects various patterns on data.
            For the big organizations branding is important and it will become more easy to target relatable customer base.
            It is similar to data mining because it is also deals with the huge amount of data.
          </p>
          <h2 className="text-3xl font-bold mb-4">Features of Machine learning</h2>
          <p className="text-lg mb-8">
            Machine learning is data driven technology. Large amount of data generated by organizations on daily bases. So, by notable relationships in data, organizations makes better decisions.
            Machine can learn itself from past data and automatically improve.
            From the given dataset it detects various patterns on data.
            For the big organizations branding is important and it will become more easy to target relatable customer base.
            It is similar to data mining because it is also deals with the huge amount of data.
          </p>
        </div>
      </section>
    </div>
  );
};

export default PySpark_Page;