
import {  Route, Routes } from "react-router-dom";
import Navbar from "./FrontEnd/MainComponets/Header/pages";
import Testimonials from "./FrontEnd/Components/Testrimonials/page";
import Home from "./FrontEnd/MainComponets/Home/home";
import { Footer } from "./FrontEnd/MainComponets/Footer/page";
import Aboutus from "./FrontEnd/Components/AboutUS/Aboutus";
import PrivacyPolicy from "./FrontEnd/Additional/Private_Policy/page";
import TermsAndConditions from "./FrontEnd/Additional/TermsandCondition/page";
import Contactpage from "./FrontEnd/Additional/Contact_Us/contactpage";
import Page from "./FrontEnd/Additional/Underconstruction/page";
import PySpark from "./FrontEnd/Components/Content_Table/PySpark_Page";
import JobPage from "./FrontEnd/Components/Job/JobPage";
import CancellationRefundPolicy from "./FrontEnd/Additional/Refund_Page/page";
import AzureDataFactory from "./FrontEnd/Components/Content_Table/AzureDataFactory_Page";
import AzureDatabricks from "./FrontEnd/Components/Content_Table/AzureDatabricks_Page";




function App() {
  

  return (
    <>

    <Navbar/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Private" element={<PrivacyPolicy />} />
          <Route path="/Terms" element={<TermsAndConditions />} />
          <Route path="/Contact" element={<Contactpage />} />
          <Route path="/UnderWork" element={<Page />} />
          <Route path="/PySpark" element={<PySpark />} />
          <Route path="/Job" element={<JobPage />} />
          <Route path="/Refund" element={<CancellationRefundPolicy/>} />
          <Route path="/AzureDataFactory" element={<AzureDataFactory/>} />
          <Route path="/AzureDatabricks" element={<AzureDatabricks/>} />
        </Routes>
        
          
        
      
      <Footer/>
     
      
    </>
  );
}

export default App;
