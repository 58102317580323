import React from 'react';
import Details from '../../Components/Details/Page';
import ContactUsButtons from '../../Components/ContactUs/ContactPage';
import Upcoming from '../../Components/UpcomingCourse/Pages';
import CourseContainer from '../../Components/Course/Course';
import MyCertificate from '../../Components/Certificate/Certificate';
import Mentors from '../../Components/Mentor/page';
import LogoMarquee from '../../Additional/LogoMarquee/LogoMarquee';
import Video from '../../Components/Video/Video';
import FAQ from '../../Components/Faq/FaqPage';
import CustomMarquee from '../../Components/Marquee/Marquee';
import CompanyMarquee from '../../Additional/LogoMarquee/CompanyMarquee';
import Comment from '../../Components/Comment/Comment';
import DisqusComments from '../../Components/Comment/Comment';
const articlePost = {
  slug: 'example-post-slug',
  title: 'Example Post Title'
};


const Home: React.FC = () => {
  return (
    <div className='space-y-8'>
      <Details />
      <CustomMarquee />
      <ContactUsButtons />
      <Upcoming />
      <CourseContainer />
      <MyCertificate />
      <Mentors />
      <LogoMarquee />
      <CompanyMarquee />
      <Video />
      <FAQ />
      <Comment />
      <DisqusComments post={articlePost} />
    </div>
  );
};

export default Home;