
import { DiscussionEmbed } from 'disqus-react';

interface PostProps {
  slug: string;
  title: string;
}

interface DisqusCommentsProps {
  post?: PostProps;
}

function DisqusComments({ post }: DisqusCommentsProps) {
  if (!post) {
    return null; // Render nothing if post is undefined
  }

  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  const disqusConfig = {
    url: pageUrl,
    identifier: post.slug,
    title: post.title,
  };

  return <DiscussionEmbed shortname='forumdeofficial' config={disqusConfig} />;
}

export default DisqusComments;
