import React from 'react';
import FastMarquee from 'react-fast-marquee';




const BottomHeader: React.FC = () =>  {
//   const BottomData = [
//       { id: 1, title: "PySpark", link: "/PySpark" },
//       { id: 2, title: "Azure Data Factory", link: "/AzureDataFactory" },
//       { id: 3, title: "Azure Databricks", link: "/AzureDatabricks" },
//   ];

  return (
    //   <nav className="bottomheader w-full h-20 bg-orange-100 flex justify-between items-center"> {/* Increased height */}
    //       <div className="w-[10%] font-bold text-black text-2xl bg-green-400 shadow-xl shadow-slate-50 rounded-r-3xl border-r-2 border-orange-600">
    //           <h1 className="px-2">Learn With Us</h1>
    //       </div>
    //       <div className="flex justify-end space-x-9">
    //           {BottomData.map((data) => (
    //               <a 
    //                   href={data.link}
    //                   className="text-2xl duration-500 hover:underline text-center text-zinc-800 font-semibold hover:text-green-600 px-5"
    //                   key={data.id}
    //               >
    //                   {data.title}
    //               </a>
    //           ))}
    //       </div>
    //   </nav>
    <div className='bg-yellow-200 text-3xl' data-aos="fade-up" data-aos-delay="400">
      <FastMarquee>
        <div>
        From the ForumDE Team Wishing you a HAPPY DIWALI and CHHATH PUJA to everyone.
          
          From the ForumDE Team Wishing you a HAPPY DIWALI and CHHATH PUJA to everyone.
          
          From the ForumDE Team Wishing you a HAPPY DIWALI and CHHATH PUJA to everyone.
          
          From the ForumDE Team Wishing you a HAPPY DIWALI and CHHATH PUJA to everyone.

        </div>
      </FastMarquee>
    </div>

  );
};

export default BottomHeader;
